import React from "react";
import PageInnerNavigator, {
  PageInnerNavigatorItemProps,
} from "./careers/new/pageInnerNavigator";

const links: PageInnerNavigatorItemProps[] = [
  {
    anchor: "Entry",
    name: "LINEエントリー",
  },
];

export const EntryHeader = () => <PageInnerNavigator items={links} />;
