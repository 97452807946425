import React, { useEffect, useState } from "react";
import * as styles from "../../../styles/careers/intern.module.scss";
import { RecruitNewbiewsLayout } from "../../../organisms/careers/RecruitNewbiewsLayout";
import { EntryHeader } from "../../../organisms/menus/EntryHeader";
import { Link } from "gatsby";
import Scroll, { scrollImageColorTypes } from "../../../atoms/newScroll";
import { EntryBox } from "../../../atoms/EntryBox";
import { LinkMargin2 } from "../../../atoms/LinkMargin2";
import { SEO } from "../../../atoms/seo/SEO";
import seoImage from "../../../images/career_new_top/_kpo0802_newgraduate_head.jpg";
import LineIcon from "../../../images/ad/line.png";
import FadeIn from "../../../atoms/fadein/FadeIn";
import { MAX_WINDOW_HEIGHT, THLESHOLD_WIDTH_SP } from ".";

export default ({ location: { pathname } }) => {
  const TOP_OTHER_DOM_HEIGHT = 144;

  let innerHeight = 960;
  if (typeof window !== "undefined") {
    innerHeight = window.innerHeight;
  }
  const [titleHeight, setTitleHeight] = useState(innerHeight);
  const [isSp, setIsSp] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsSp(window.innerWidth < THLESHOLD_WIDTH_SP);

      if (!isSp) {
        const topImageDom = document.querySelector(
          "[data-career-top-image=data-career-top-image]"
        );
        const topImageDomHeight = topImageDom?.getBoundingClientRect().height;
        const innerHeight =
          window.innerHeight <= MAX_WINDOW_HEIGHT
            ? window.innerHeight
            : MAX_WINDOW_HEIGHT;

        if (topImageDomHeight) {
          setTitleHeight(
            innerHeight - topImageDomHeight - TOP_OTHER_DOM_HEIGHT
          );
        }
      }
    }, 300);

    window.addEventListener("resize", () => {
      setIsSp(window.innerWidth < THLESHOLD_WIDTH_SP);

      setTimeout(() => {
        if (!isSp) {
          const topImageDom = document.querySelector(
            "[data-career-top-image=data-career-top-image]"
          );
          const topImageDomHeight = topImageDom?.getBoundingClientRect().height;
          const innerHeight =
            window.innerHeight <= MAX_WINDOW_HEIGHT
              ? window.innerHeight
              : MAX_WINDOW_HEIGHT;

          if (topImageDomHeight) {
            setTitleHeight(
              innerHeight - topImageDomHeight - TOP_OTHER_DOM_HEIGHT
            );
          }
        }
      }, 300);
    });
  }, []);

  return (
    <RecruitNewbiewsLayout isSp={isSp} current="entry">
      <SEO
        pathname={pathname}
        title="新卒採用エントリー | Entry"
        description="採用基準は「友達になれそうな人」！元芸人が創った会社が就職企業人気ランキング人材部門3位！未上場成長率1位の注目企業！エントリーはこちらから、弊社LINE公式アカウントに登録して頂き選考にスムーズに進んでいただくことが出来ます。"
        image={seoImage}
      />
      <FadeIn>
        <div
          className={styles.EntryTitle}
          style={!isSp ? { height: titleHeight } : {}}
        >
          <h2>Entry</h2>
          <h3>新卒採用エントリー</h3>
        </div>
      </FadeIn>

      <EntryHeader />

      <LinkMargin2 />
      <div className={styles.Entry}>
        <a
          id="Entry"
          data-career-page-section-index="0"
          style={{ position: "absolute", marginTop: "-120px" }}
        />
        <div className={styles.Entry__heading}>
          <img className={styles.Entry__imgSmall} src={LineIcon} alt="" />
          <p className={styles.Entry__headingText}>エントリーはこちら</p>
        </div>

        <h4 className={styles.Entry__messagePc}>
          LINEにて新卒採用へのご応募を受け付けております。
          <br />
          QRコードよりご登録ください。
        </h4>
        <h4 className={styles.Entry__messageSp}>
          LINEにて新卒採用へのご応募を
          <br />
          受け付けております。
        </h4>
        <EntryBox />
      </div>
    </RecruitNewbiewsLayout>
  );
};
