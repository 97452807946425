import * as styles from "../styles/careers/intern.module.scss";
import QrIcon from "../images/entryLineQr.png";
import React from "react";

export const EntryBox = () => (
  <div className={styles.Entry__box}>
    <div className={styles.Entry__containerLeft}>
      <a
        href={
          "https://liff.line.me/1645278921-kWRPP32q?accountId=jqa6042t&openerPlatform=native&openerKey=urlScheme%3Ainternal#mst_challenge=Ko84PCKDMw_JENuVeBUMfNcCTKLXARNm5tA3jRiFhHA"
        }
        target={"_blank"}
      >
        <img className={styles.Entry__img} src={QrIcon} alt="" />
      </a>
    </div>
    <div className={styles.Entry__containerRight}>
      <div className={styles.Entry__containerSmall}>
        <h5>Suprieve採用アカウント</h5>
      </div>
      <p className={styles.Entry__qrExplain}>
        <span className={styles.Entry__lineTextPc}>QRコードより</span>
        <span className={styles.Entry__lineText}>LINE</span>
        にご登録のうえ、
        <br className={styles.Entry__br} />
        お問い合わせください
      </p>
    </div>
    <a href="#" className={styles.Entry__entryLinkSp}>
      友達追加で採用エントリー
    </a>
  </div>
);
